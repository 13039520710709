import React from "react";
import TableLoader from './TableLoader';

export default function PageLoading() {
  return (
    <div className="flex justify-center items-center min-h-screen">
      {/* <h4 className="text-2xl text-red-600 font-bold">
        70 D - <span className="text-gray-800 font-bold">Tech</span>
      </h4> */}
      <TableLoader />
    </div>
  );
}
