import React from 'react'

function Topbar() {
    return (
        <div className="bg-blue-500">
            <div className="max-w-screen-xl mx-auto px-4 py-2 text-white sm:text-center md:px-8">
                <p className="font- text-md">
                    This is a beta version of our application !
                </p>
            </div>
        </div>
    )
}

export default Topbar