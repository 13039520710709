import React, { Fragment, useState, useEffect } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import { HiOutlineBell, HiOutlineSearch, HiOutlineChatAlt, HiLogout, HiOutlineLogout, } from 'react-icons/hi'
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Drawer } from "@mui/material";
import SidebarDrawer from './SidebarDrawer';
import CommonDialog from '../common/CommonDialog';
import { FaBell } from 'react-icons/fa';
import { Settings, SettingsCellOutlined, SettingsOutlined } from '@mui/icons-material';
import { CiSettings } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { IoMdNotificationsOutline } from "react-icons/io";

export default function Header() {
	const navigate = useNavigate();
	const [openLogoutDialog, setOpenLogoutDialog] = useState(false);

	const [companyName, setCompanyname] = useState("");
	const [adminname, setAdminName] = useState("");
	const [drawerOpen, setDrawerOpen] = useState(false);
	const [siderbarClose, setSideBarClose] = useState(false);

	const handleLogoutDialogClick = () => {
		setOpenLogoutDialog(!openLogoutDialog)
	};

	const handleDrawerClick = () => {
		setDrawerOpen(!drawerOpen);
	};
	const handleSiderBarClick = () => {
		setSideBarClose(!siderbarClose);
	};


	useEffect(() => {
		// Function to retrieve data from local storage
		const fetchDataFromLocalStorage = () => {
			const storedDatacompany = localStorage.getItem("CompanyName");
			const storedDataadmin = localStorage.getItem("Username");
			setCompanyname(storedDatacompany);
			setAdminName(storedDataadmin);
		};

		fetchDataFromLocalStorage();
	}, []);


	return (
		<div className="bg-sidecolor h-14 py-2 px-4 flex items-center border-b  border-gray-200 justify-between">
			<div className="relative flex items-center">
				{/* Anything */}
				{/* <HiOutlineSearch fontSize={20} className="text-gray-400 absolute top-1/2 left-3 -translate-y-1/2" />
				<input
					type="text"
					placeholder="Search..."
					className="text-sm focus:outline-none active:outline-none border border-gray-300 w-[24rem] h-10 pl-11 pr-4 rounded-sm"
		 		/> */}
				<div
					className='lg:hidden'
				>
					<IconButton onClick={handleDrawerClick}>
						<MenuIcon />
					</IconButton>

				</div>
				<h5>{companyName?.length > 20 ? companyName.slice(0, 20) + '...' : companyName}</h5>
			</div>

			<div className="flex items-center gap-6 mr-2">
				<Popover className="relative">
					{({ open }) => (
						<>
							{/* <h5>{adminname?.length > 15 ? adminname.slice(0, 15) + '...' : adminname}</h5> */}
							<h5>{adminname}</h5>

							<Transition
								as={Fragment}
								enter="transition ease-out duration-200"
								enterFrom="opacity-0 translate-y-1"
								enterTo="opacity-100 translate-y-0"
								leave="transition ease-in duration-150"
								leaveFrom="opacity-100 translate-y-0"
								leaveTo="opacity-0 translate-y-1"
							>
								<Popover.Panel className="absolute right-0 z-10 mt-2.5 transform w-80">
									<div className="bg-white rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
										<strong className="text-gray-700 font-medium">Messages</strong>
										<div className="mt-2 py-1 text-sm">This is messages panel.</div>
									</div>
								</Popover.Panel>
							</Transition>
						</>
					)}
				</Popover>
				{/* <Popover className="relative">
					{({ open }) => (
						<>
							<Popover.Button
								className={classNames(
									open && 'bg-gray-100',
									'group inline-flex items-center rounded-sm p-1.5 text-gray-700 hover:text-opacity-100 focus:outline-none active:bg-gray-100'
								)}
							>
								<HiOutlineBell fontSize={24} />
							</Popover.Button>
							<Transition
								as={Fragment}
								enter="transition ease-out duration-200"
								enterFrom="opacity-0 translate-y-1"
								enterTo="opacity-100 translate-y-0"
								leave="transition ease-in duration-150"
								leaveFrom="opacity-100 translate-y-0"
								leaveTo="opacity-0 translate-y-1"
							>
								<Popover.Panel className="absolute right-0 z-10 mt-2.5 transform w-80">
									<div className="bg-white rounded-sm shadow-md ring-1 ring-black ring-opacity-5 px-2 py-2.5">
										<strong className="text-gray-700 font-medium">Notifications</strong>
										<div className="mt-2 py-1 text-sm">This is notification panel.</div>
									</div>
								</Popover.Panel>
							</Transition>
						</>
					)}
				</Popover> */}
				<Link to='/dashboard/settings' className='text-black'>
					<span>
						<IoSettingsOutline   size={20} color='black' />
					</span>
				</Link>
				<Link to='/dashboard/notifications' className=''>
					<span>
						<IoMdNotificationsOutline  size={24} color='black' />
					</span>
				</Link>
				{/* <Link to='/' className='text-red'> */}
					<span>
						<HiOutlineLogout   onClick={handleLogoutDialogClick} size={24} color='red' />
					</span>
				{/* </Link> */}

				{/* </IconButton> */}
				<Menu as="div" className="relative">
					<div>
						<Menu.Button className="ml-2 bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
							<span className="sr-only">Open user menu</span>
							<div
								className="h-10 w-10 rounded-full bg-cover bg-no-repeat bg-center"
								style={{ backgroundImage: 'url("http://www.ipilhsr.com/hsm/images/user_images/user-default-icon.jpg")' }}
							>
								<span className="sr-only">Marc Backes</span>
							</div>
						</Menu.Button>
					</div>
					<Transition
						as={Fragment}
						enter="transition ease-out duration-100"
						enterFrom="transform opacity-0 scale-95"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-75"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-95"
					>
						<Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-48 rounded-sm shadow-md p-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
							<Menu.Item>
								{({ active }) => (
									<div
										onClick={() => navigate('/profile')}
										className={classNames(
											active && 'bg-gray-100',
											'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
										)}
									>
										Your Profile
									</div>
								)}
							</Menu.Item>
							<Menu.Item>
								{({ active }) => (
									<div
										onClick={() => navigate('/settings')}
										className={classNames(
											active && 'bg-gray-100',
											'active:bg-gray-200 rounded-sm px-4 py-2 text-gray-700 cursor-pointer focus:bg-gray-200'
										)}
									>
										Settings
									</div>
								)}
							</Menu.Item>
							<Menu.Item>
								{({ active }) => (
									<div
										onClick={handleLogoutDialogClick}
										className={classNames(
											active && 'bg-gray-100',
											'active:bg-gray-200 rounded-sm px-4 py-2 text-red-500 cursor-pointer focus:bg-gray-200'
										)}
									>
										Sign out
									</div>
								)}
							</Menu.Item>
						</Menu.Items>
					</Transition>
				</Menu>
			</div>

			{/* siderbar drawer when widht reaches 1024px */}
			<SidebarDrawer
				open={drawerOpen}
				onClose={handleDrawerClick}
			/>
			<CommonDialog
				type="logout"
				open={openLogoutDialog}
				onClose={handleLogoutDialogClick}
			/>
		</div>
	)
}