import React from "react";
import { Dialog, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Close from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
  absentAsPresentDialogContainer: {
    "& .MuiDialog-paperWidthSm": {
      width: "30%",
      height: "auto",
      overflow: "hidden",
      boxSizing: "border-box",
    },

    "& .dialog-content": {
      width: "100%",
      height: "100%",
      overflow: "hidden",

      "& .closeIcon": {
        position: "absolute",
        top: "0",
        right: "0",
      },

      "& p": {
        padding: "20px",
      },

      "& .btn-container": {
        padding: "20px",
        paddingTop: "10px"
      },
    },
  },
}));

export default function CommonDailog({ type, open, onClose }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    localStorage.clear();
    toast.success("Logout Successfully!");
    navigate("/");
  }

  return (
    <>

      {type === "absent-present" && (
        <Dialog className={classes.absentAsPresentDialogContainer} open={open}>
          <div className="dialog-content">
            <IconButton onClick={onClose} className="closeIcon">
              <Close />
            </IconButton>

            <div>
              <p className="break-all text-black text-base font-semibold leading-6">
                Mark all absent as present?
              </p>
              <div className="border-t border-b border-solid border-gray-300">
                <p className="text-slate-800 font-light text-base text-sm">
                  Are you sure you want to mark all absent as present?
                </p>
              </div>

              <div className="mt-2 btn-container flex justify-end gap-2">
                <button
                  className="bg-white border border-lightgray text-base font-normal text-sm h-8 leading-6 px-4 transition duration-300 ease-in-out select-none whitespace-nowrap font-light text-base"
                  onClick={onClose}
                >
                  Cancle
                </button>

                <button className="bg-blue-500 border border-blue-500 shadow-md text-white text-base font-normal text-sm h-8 leading-6 px-4 transition duration-300 ease-in-out select-none whitespace-nowrap hover:bg-white-600 hover:border-blue-600 hover:text-blue">
                  Mark All Absent As Present
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      )}

      {type === "logout" && (
        <Dialog className={classes.absentAsPresentDialogContainer} open={open}>
          <div className="dialog-content">
            <IconButton onClick={onClose} className="closeIcon">
              <Close />
            </IconButton>

            <div>
              <p className="break-all text-black text-base font-semibold leading-6">
                Confirm logout?
              </p>
              <div>
                <p className="text-slate-800 font-light text-base text-sm">
                  Are you sure you want to logout?
                </p>
              </div>

              <div className="mt-2 btn-container flex justify-end gap-2">
                <button
                  className="bg-white border border-lightgray text-base font-normal text-sm h-8 leading-6 px-4 transition duration-300 ease-in-out select-none whitespace-nowrap font-light text-base"
                  onClick={onClose}
                >
                  Cancel
                </button>

                <button
                  className="border border-lightgray text-red-600 text-base font-normal text-sm h-8 leading-6 px-4 transition duration-300 ease-in-out select-none whitespace-nowrap hover:text-red-700"
                  onClick={handleLogoutClick}
                >
                  Log Out
                </button>

              </div>
            </div>
          </div>
        </Dialog>
      )}

    </>
  );
}
