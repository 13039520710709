import React, { useEffect, useState } from "react";
import { Drawer } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { HiOutlineLogout, HiOutlineChevronDown } from "react-icons/hi";
import CommonDialog from "../common/CommonDialog";
import axios from "axios";
import { toast } from "react-toastify";
import { DASHBOARD_SIDEBAR_LINKS } from "../utils/navigation";
import ApiConfig from "../../config/ApiConfig";
import { makeStyles } from "@mui/styles";

const linkClass = "flex items-center m-1 gap-2 font-light px-3 py-2 hover:bg-blue-500 hover:text-white hover:no-underline active:bg-neutral-600 rounded-md text-base";

const useStyles = makeStyles(() => ({
  sidebarContainer: {
    "&::-webkit-scrollbar": {
      display: "none",
      msOverflowStyle: "none",
      scrollbarWidth: "none",
    },
  },
}));

export default function SidebarDrawer({ open, onClose }) {
  const classes = useStyles();
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [expandedKey, setExpandedKey] = useState(null);
  const [filteredLinks, setFilteredLinks] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    const storedExpandedKey = localStorage.getItem('expandedKey');
    if (storedExpandedKey) {
      setExpandedKey(storedExpandedKey);
    }
  }, []);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const role_id = localStorage.getItem('RoleID');
        const response = await axios.get(`${ApiConfig.getrolemenubyroleid}/${role_id}`);
        const menuMapping = response.data.data.menu_mapping;

        const filteredLinks = DASHBOARD_SIDEBAR_LINKS.map(link => {
          const matchedMenu = menuMapping.find(menu => menu.menu_name === link.label);
          if (matchedMenu) {
            if (link.items) {
              link.items = link.items.filter(subLink =>
                matchedMenu.submenus.some(submenu => submenu.submenu_name === subLink.label)
              );
            }
            return link;
          }
          return null;
        }).filter(Boolean);

        setFilteredLinks(filteredLinks);
      } catch (error) {
        toast.error('Failed to fetch menu data');
      }
    };

    fetchMenuData();
  }, []);

  const handleExpand = (key, hasSubmenus) => {
    const newExpandedKey = expandedKey === key ? null : key;
    setExpandedKey(newExpandedKey);
    localStorage.setItem('expandedKey', newExpandedKey);
    if (!hasSubmenus) {
      localStorage.removeItem('activeSubmenu');
    }
  };

  const handleLogoutDialogClick = () => {
    setOpenLogoutDialog(!openLogoutDialog);
  };

  return (
    <Drawer open={open} onClose={onClose}>
      <div className={`flex flex-col bg-sidecolor border-r-2 h-screen w-60 overflow-auto ${classes.sidebarContainer}`}>
        <div className="flex items-center gap-2 px-1 py-1">
          <div className="p-4">
            <h1 className="text-red-600 text-2xl font-bold">
              Hr<span className="text-black font-bold">MS</span>
            </h1>
          </div>
        </div>
        <div className="flex-1 py-2 flex flex-col gap-0.2">
          {filteredLinks.map((item) => (
            <SidebarLink
              key={item.key}
              item={item}
              expandedKey={expandedKey}
              handleExpand={handleExpand}
            />
          ))}

        </div>
        <div className={classNames(linkClass, 'cursor-pointer text-red-500')} onClick={handleLogoutDialogClick}>
          <span className="text-xl">
            <HiOutlineLogout />
          </span>
          Logout
        </div>
      </div>
      <CommonDialog
        type="logout"
        open={openLogoutDialog}
        onClose={handleLogoutDialogClick}
      />
    </Drawer>
  );
}

function SidebarLink({ item, expandedKey, handleExpand }) {
  const { pathname } = useLocation();
  const isExpanded = expandedKey === item.key;

  const handleClick = () => {
    handleExpand(item.key, item.submenus || item.items ? true : false);
  };

  const handleSubmenuClick = (path) => {
    localStorage.setItem('activeSubmenu', path);
  };

  return (
    <>
      {item.submenus || item.items ? (
        <div>
          <div
            className={classNames(
              pathname === item.path ? " bg-blue-500 text-white" : "text-black",
              linkClass
            )}
            onClick={handleClick}
          >
            <span className="text-xl">{item.icon}</span>
            {item.label}
            <span className="ml-auto">
              <HiOutlineChevronDown />
            </span>
          </div>
          <div className={`pl-5 ${isExpanded ? "block" : "hidden"}`}
            style={{
              maxHeight: '300px', // Set the desired height for the submenu
              overflowY: 'auto',   // Add vertical scrollbar if the content exceeds the height
            }}
          >
            {item.submenus.map((subItem) => (
              <Link
                to={subItem.path}
                key={subItem.key}
                className={classNames(
                  pathname === subItem.path ? " bg-blue-500 text-white" : "text-black",
                  linkClass
                )}
                onClick={() => handleSubmenuClick(subItem.path)}
              >
                <span className="text-xl">{subItem.icon}</span>
                {subItem.label}
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <Link
          to={item.path}
          className={classNames(
            pathname === item.path ? " bg-blue-500 text-white" : "text-black",
            linkClass
          )}
          onClick={handleClick}
        >
          <span className="text-xl">{item.icon}</span>
          {item.label}
        </Link>
      )}
    </>
  );
}

