// const baseUrl = "http://192.168.220.138:8000/";
const baseUrl = "https://api.70d.tech/";

const user = `${baseUrl}api`;

const ApiConfig = {
  // Menu Mapping
  getallmenuMap: `${user}/get_all_menus_details`,
  postmenumap: `${user}/post_roleMenumap`,
  updatemenumap: `${user}/update_role_map_menu_submenu_data`,
  getrolemenubyroleid: `${user}/roles/menus`,
  getallmenuandsubmenu:`${user}/get_all_menumap`,

  //role
  getallrole: `${user}/get_all_roles_details`,
  getrolebyid: `${user}/get_by_role`,

  loginAdmin: `${user}/login_admin`,
  VerifyOtp: `${user}/admin_login_verification`,
  getAllAdminsDetails: `${user}/get_all_admins_details`,
  getAllAdminByCompanyID: `${user}/get_admin_by_companyID`,
  getAdmincountbycompanyID: `${user}/get_admin_count_by_companyID`,
  getDepartmentcountbycompanyID: `${user}/get_department_count_by_companyID`,

  // Role Type
  getRolesbyCompanyID: `${user}/get_roles_by_companyID`,
  postRoledata: `${user}/post_role_data`,
  deleteRoledata: `${user}/delete_role_data`,
  updateRoledata: `${user}/update_role_data`,

  // Bank Details
  getBank: `${user}/get_bank_by_compnayID`,
  AddBank: `${user}/post_bank_data`,
  updateBank: `${user}/update_bank_data`,
  deleteBank: `${user}/delete_bank_data`,
  getBankExcel: `${user}/get_bank_excel_export`,
  getdetailsifsc: `${user}/get_bank_by_ifsc_code`,

  // Employee Categories
  getEmployeecategorybycompanyID: `${user}/get_employee_category_by_companyID`,
  postEmployeecategorydata: `${user}/post_employee_category_data`,
  deletEmployeecategorydata: `${user}/delete_employee_category_data`,
  updateEmployeecategorydata: `${user}/update_employee_category_data`,
  getEmployeeCategoryExcel: `${user}/get_employee_category_excel_export`,

  // Department 
  getAlldepartmentdetailsbycompanyID: `${user}/get_all_department_details_by_companyID`,
  AddDepartment: `${user}/post_department_data`,
  deleteDepartmentdata: `${user}/delete_department_data`,
  updateDepartmentdata: `${user}/update_department_data`,
  getDepartmentExel: `${user}/get_department_excel_export`,

  // Designation
  getDesignationbycompanyID: `${user}/get_designation_by_companyID`,
  AddDesignation: `${user}/post_designation_data`,
  updateDesignation: `${user}/update_designation_data`,
  deleteDesignationdata: `${user}/delete_designation_data`,
  getDesignationExcel: `${user}/get_designation_excel_export`,


  // Financial Year
  getFinancialYear: `${user}/get_financial_year_by_companyID`,
  AddFinancialYear: `${user}/post_financial_year_data`,
  updateFianacialYear: `${user}/update_financial_year_data`,
  deleteFinancialYear: `${user}/delete_financial_year_data`,
  getFinancialYearExel: `${user}/get_financial_year_excel_export`,
  donwloadFinancialTemplate: `${user}/get_financial_year_template`,

  //Employee Status
  getEmpStatus: `${user}/get_employee_status_by_companyID`,
  AddEmpStatus: `${user}/post_employee_status_data`,
  updateEmployeeStatus: `${user}/update_employee_status_data`,
  deleteEmpStatus: `${user}/delete_employee_status_data`,

  // Holiday
  getHolidaybycompanyID: `${user}/get_holiday_by_companyID`,
  AddHoliday: `${user}/post_holiday_data`,
  updateHoliday: `${user}/update_holiday_data`,
  deletHoliday: `${user}/delete_holiday_data`,
  getHolidayExcel: `${user}/get_holiday_excel_export`,

  // Resign Type
  getResignbycompanyID: `${user}/get_resign_type_by_companyID`,
  AddResignType: `${user}/post_resign_type_data`,
  updateResignType: `${user}/update_resign_type_data`,
  deletResign: `${user}/delete_resign_type_data`,

  // Grade
  getGrade: `${user}/get_grades_by_companyID`,
  AddGrade: `${user}/post_grade_data`,
  updateGrade: `${user}/update_grade_data`,
  deletGrade: `${user}/delete_grade_data`,
  getGradeExcel: `${user}/get_grade_excel_export`,

  // Employee Details
  getEmployee: `${user}/get_all_employee_details`,
  AddEmployee: `${user}/post_employee_data`,
  ConvertToMarathi: `${user}/get_convert_name_to_marathi`,
  getEmployeeExcel: `${user}/export_employee_data`,
  getdetailspincode: `${user}/get_all_pincode_details`,
  deleteEmployee: `${user}/delete_employee_data`,

  //admin
  registerAdminData: `${user}/register_admin_data`,
  updateAdminData: `${user}/update_admin_data`,
  deleAdminData: `${user}/delete_admin_data`,
  // Location
  getLocation: `${user}/get_location_by_companyID`,
  AddLocation: `${user}/post_location_data`,
  updateLocation: `${user}/update_location_data`,
  deletLocation: `${user}/delete_location_data`,
  getLocationExcel: `${user}/get_location_excel_export`,

  // Section
  getSection: `${user}/get_sections_by_companyID`,
  AddSection: `${user}/post_section_data`,
  updateSection: `${user}/update_section_data`,
  deletSection: `${user}/delete_section_data`,

    // OT Slab
   getOTSlab: `${user}/get_ot_slab_by_companyID`,
    AddOtSlab: `${user}/post_ot_slab_data`,
   updateOTSlab: `${user}/update_ot_slab_data`,
    deletOTSlab: `${user}/delete_ot_slab_data`,

  // OT Rule
  getOTRule: `${user}/get_ot_rule_by_companyID`,
  AddOtRule: `${user}/post_ot_rule_data`,
  updateOTRule: `${user}/update_ot_rule_data`,
  deletOTRule: `${user}/delete_ot_rule_data`,

    // OT Slab
    // getOTSlab: `${user}/get_ot_slab_by_companyID`,
    // AddOtSlab: `${user}/post_ot_slab_data`,
    // updateOTSlab: `${user}/update_ot_rule_data`,
    // deletOTSlab: `${user}/delete_ot_rule_data`,
    getCostExcel: `${user}/get_cost_center_excel_export`,

    // Cost Center
    getCostCenter: `${user}/get_cost_center_by_companyID`,
    AddCostCenter: `${user}/post_cost_center_data`,
    updateCostCenter: `${user}/update_cost_center_data`,
    deletCostCenter: `${user}/delete_cost_center_data`,
    donwloadCostCenterTemplate:`${user}/get_cost_center_template`,

    // Sub Contract
    getSubContract: `${user}/get_sub_contract_by_companyID`,
    AddSubContract: `${user}/post_sub_contract_data`,
    updateSubContract: `${user}/update_sub_contract_data`,
    deletSubContract: `${user}/delete_sub_contract_data`,

    // Project
    getProjects: `${user}/get_project_by_companyID`,
    AddProject: `${user}/post_project_data`,
    updateProject: `${user}/update_project_by_ProjectID`,
    deletProject: `${user}/delete_project_by_ProjectID`,

      // Attendance Routes
  getDailyAttendance: `${user}/get_daily_attendance`,
};

export default ApiConfig;
