import {
    HiOutlineViewGrid,
    HiOutlineCube,
    HiOutlineShoppingCart,
    HiOutlineUsers,
    HiOutlineDocumentText,
    HiOutlineAnnotation,
    HiOutlineQuestionMarkCircle,
    HiOutlineCog,
    HiUser,
    HiMenu,
    HiOutlineChip,
    HiOutlineCalendar,
    HiOutlineGlobeAlt,
    HiOutlineBriefcase,
    HiOutlinePlay,
    HiOutlineViewBoards,
    HiOutlineSun,
    HiOutlineDocument,
    HiOutlineLocationMarker,
    HiOutlineCurrencyRupee,
    HiOutlineInbox,
    HiOutlineDatabase,
    HiOutlineAcademicCap,
    HiOutlineFilter,
    HiOutlineStatusOnline,
    HiOutlineRewind,
    HiOutlinePencil,
    HiOutlineUser,
    HiOutlineCash,
    HiOutlinePaperAirplane,
    HiOutlineDocumentReport,
    HiOutlineClock,
    HiOutlineCloud,
    HiOutlineVolumeUp,
    HiOutlineAdjustments,
    HiOutlineMap
} from 'react-icons/hi'

import { RiLockPasswordFill } from "react-icons/ri";
import { FaEye } from "react-icons/fa";
import { FaUserCog } from "react-icons/fa";

export const DASHBOARD_SIDEBAR_LINKS = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        path: '/dashboard',
        icon: <HiOutlineViewGrid />
    },
    {
        key: 'admin',
        label: 'Admin',
        path: '/dashboard/admin',
        icon: <HiOutlineCube />,
        submenus: [
            {
                key: 'useradmin',
                label: 'User Admin',
                path: '/dashboard/admin/useradmin',
                icon: <HiOutlineCurrencyRupee />
            },
            {
                key: 'menumaster',
                label: 'Menu Master',
                path: '/dashboard/admin/menumaster',
                icon: <HiOutlineCurrencyRupee />
            },
        ]
    },
    // Master
    {
        key: 'masters',
        label: 'Master',
        path: '/dashboard/masters',
        icon: <HiOutlineChip />,
        submenus: [
            {
                key: 'bank',
                label: 'Bank',
                path: '/dashboard/masters/bank',
                icon: <HiOutlineCurrencyRupee />
            },
            {
                key: 'costcenter',
                label: 'Cost Center',
                path: '/dashboard/masters/costcenter',
                icon: <HiOutlineShoppingCart />
            },
            {
                key: 'department',
                label: 'Department',
                path: '/dashboard/masters/department',
                icon: <HiOutlineDatabase />
            },
            {
                key: 'designation',
                label: 'Designation',
                path: '/dashboard/masters/designation',
                icon: <HiOutlineAcademicCap />
            },
            {
                key: 'employeecategory',
                label: 'EMP Category',
                path: '/dashboard/masters/employeecategory',
                icon: <HiOutlineFilter />
            },
            {
                key: 'employeestatus',
                label: 'EMP Status',
                path: '/dashboard/masters/employeestatus',
                icon: <HiOutlineStatusOnline />
            },
            {
                key: 'finacialyear',
                label: 'Financial Year',
                path: '/dashboard/masters/finacialyear',
                icon: <HiOutlineCalendar />
            },
            {
                key: 'grade',
                label: 'Grade',
                path: '/dashboard/masters/grade',
                icon: <HiOutlineViewGrid />
            },
            {
                key: 'locations',
                label: 'Location',
                path: '/dashboard/masters/locations',
                icon: <HiOutlineLocationMarker />
            },
            {
                key: 'otrule',
                label: 'OT Rule',
                path: '/dashboard/masters/otrule',
                icon: <HiOutlineRewind />
            },
            {
                key: 'otslab',
                label: 'OT Slab',
                path: '/dashboard/masters/otslab',
                icon: <HiOutlineRewind />
            },
            {
                key: 'resigntype',
                label: 'Resign Type',
                path: '/dashboard/masters/resigntype',
                icon: <HiOutlinePencil />
            },
            {
                key: 'roletype',
                label: 'Role Type',
                path: '/dashboard/masters/roletype',
                icon: <HiOutlineUser />
            },
            // {
            //  key: 'salarycomponent',
            //  label: 'Salary Component',
            //  path: '/dashboard/masters/salarycomponent',
            //  icon: <HiOutlineCash />
            // },
            // {
            //  key: 'salarycomponentdetail',
            //  label: 'SC Detail',
            //  path: '/dashboard/masters/salarycomponentdetail',
            //  icon: <HiOutlineCash />
            // },
            {
                key: 'section',
                label: 'Section',
                path: '/dashboard/masters/section',
                icon: <HiOutlineInbox />
            },
            {
                key: 'subcontractor',
                label: 'Sub Contract',
                path: '/dashboard/masters/subcontractor',
                icon: <HiOutlineBriefcase />
            },
            {
                key: 'holiday',
                label: 'Holiday',
                path: '/dashboard/masters/holiday',
                icon: <HiOutlineAcademicCap />
            },
        ]
    },
    {
        key: 'employee',
        label: 'Employee',
        path: '/dashboard/employee',
        icon: <HiOutlineUsers />
    },
    // {
    //  key: 'attendance',
    //  label: 'Attendance',
    //  path: '/dashboard/attendance',
    //  icon: <HiOutlineCalendar />
    // },
    {
        key: 'attendance',
        label: 'Attendance',
        path: '/dashboard/attendance',
        icon: <HiOutlineCalendar />,
        submenus: [
            {

                key: 'userAttendance',
                label: 'Daily Attendance',
                path: '/dashboard/attendance/view-attendance',
                icon: <HiOutlineUser />
            },

            // {
            //  key: 'userPrsentee',
            //  label: 'Presentee Rule',
            //  path: '/dashboard/attendance/presentee-rule',
            //  icon: <HiOutlineMap />

            // },
            // {
            //  key: 'userLD',
            //  label: 'LMED Rule',
            //  path: '/dashboard/attendance/lmed-rule',
            //  icon: <HiOutlineMap />

            // }
        ]
    },
    {
        key: 'leave',
        label: 'Leave',
        path: '/dashboard/leave',
        icon: <HiOutlineSun />
    },
    {
        key: 'document',
        label: 'Documents',
        path: '/dashboard/documents',
        icon: <HiOutlineDocument />
    },
    {
        key: 'location',
        label: 'Location',
        path: '/dashboard/location',
        icon: <HiOutlineLocationMarker />
    },
    {
        key: 'projects',
        label: 'Projects',
        path: '/dashboard/project',
        icon: <HiOutlineDocumentReport />
    },
    {
        key: 'expense',
        label: 'Expenses',
        path: '/dashboard/expense',
        icon: <HiOutlineCurrencyRupee />
    },
    {
        key: 'asset',
        label: 'Assets',
        path: '/dashboard/assets',
        icon: <HiOutlineInbox />
    },

]

export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
    {
        key: 'settings',
        label: 'Settings',
        path: '/dashboard/setting',
        icon: <HiOutlineCog />
    },
    // {
    //  key: 'support',
    //  label: 'Help & Support',
    //  path: '/dashboard/support',
    //  icon: <HiOutlineQuestionMarkCircle />
    // },

]

export const EMPLOYEE_MENU_LINK = [
    {
        key: 'settings',
        label: 'Employee Transfer',
        path: '/employee/employeeTransfer',
        icon: <HiOutlinePaperAirplane />
    },
    {
        key: 'settings',
        label: 'Employee Shift Pattern',
        path: '/employee/employeeshift',
        icon: <HiOutlineClock />
    },
    {
        key: 'settings',
        label: 'OT Rule',
        path: '/employee/otRule',
        icon: <HiOutlineCloud />
    },
    {
        key: 'settings',
        label: 'OT C-Off Rule',
        path: '/employee/otCRule',
        icon: <HiOutlineCloud />
    },
    {
        key: 'settings',
        label: 'Approval Matrix',
        path: '/employee/approvedMatrix',
        icon: <HiOutlineAdjustments />
    },
];

export const ADMIN_MENU_LINK = [
    {
        key: 'view',
        label: 'View Admin',
        path: '/dashboard/viewAdmin',
        icon: <FaEye />
    },
    {
        key: 'changePassword',
        label: 'Change Password',
        path: 'changePassword',
        icon: <RiLockPasswordFill />
    },
    {
        key: 'assignRole',
        label: 'Assign Role',
        path: 'assignRole',
        icon: <FaUserCog />
    },

]

