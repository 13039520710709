import React from "react";
import Topbar from "../../components/utils/Topbar";
import Header from "../../components/shared/Header";
import Sidebar from "../../components/shared/Sidebar";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  dashboardLayoutContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    // backgroundColor: "#F5F7F8",
        backgroundColor: "#fff",
  },
  mainContent: {
    display: "flex",
    flexGrow: 1,
    overflow: "hidden",
    // backgroundColor: "#F5F7F8",
    backgroundColor: "#fff",
  },
  sidebar: {
    width: "250px",
    flexShrink: 0,
    overflow: "hidden",
  },
  contentArea: {
    flexGrow: 1,
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  headerWrapper: {
    flexShrink: 0,
  },
  contentWrapper: {
    flexGrow: 1,
    overflowY: "auto",
    padding: "5px", // Add padding if needed for better spacing
  },
}));

export default function DashboardLayout({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.dashboardLayoutContainer}>
      <Topbar />
      <div className={classes.mainContent}>
        <Sidebar className={classes.sidebar} />

        <div id="layout-container" className={classes.contentArea}>
          <Header className={classes.headerWrapper} />

          <div className={classes.contentWrapper}>
            {children}
          </div>
        </div>
        
      </div>
    </div>
  );
}

