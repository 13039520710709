import { lazy } from "react";
import DashboardLayout from "./Layouts/DashboardLayout/DashboardLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    component: lazy(() => import("./pages/Login")),
  },
  {
    exact: true,
    path: "register",
    component: lazy(() => import("./pages/Register")),
  },
  {
    exact: true,
    path: "forgotPassword",
    component: lazy(() => import("./pages/ForgetPassword")),
  },
  {
    exact: true,
    path: "/dashboard/admin/changePassword",
    layout: DashboardLayout,
    guard: true,
    component: lazy(() => import("./views/Admin/Tabs/UserAdmin/ChangePassword")),
  },
  {
    exact: true,
    path: "/dashboard/admin/useradmin",
    guard: true,
    component: lazy(() => import("./views/Admin/Tabs/UserAdmin/UserAdmin")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/admin/menumaster",
    guard: true,
    component: lazy(() => import("./views/Admin/Tabs/MenuMaster/MenuMaster")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/setting",
    guard: true,
    component: lazy(() => import("./views/Admin/Tabs/MenuMaster/MenuMaster")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/support",
    guard: true,
    component: lazy(() => import("./views/HelpSupport/HelpandSupport")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/notifications",
    guard: true,
    component: lazy(() => import("./views/Admin/Notification")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/settings",
    guard: true,
    component: lazy(() => import("./views/Admin/Settings")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/verify-otp",
    component: lazy(() => import("./pages/OtpVerify")),
  },
  {
    exact: true,
    path: "/dashboard/masters/bank",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/Bank/Bank")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/masters/costcenter",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/CostCenter/CostCenter")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/masters/department",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/Department/Department")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/masters/designation",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/Designation/Designation")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/masters/employeecategory",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/EmployeeCategory/EmployeeCategory")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/masters/employeestatus",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/EmployeeStatus/EmployeeStatus")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/masters/finacialyear",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/FinancialYear/FinancialYear")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/masters/grade",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/Grade/Grade")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/masters/locations",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/LocationMaster/LocationMaster")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/masters/otrule",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/OTRule/OTRule")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/masters/otslab",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/OTSlab/OTSlab")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/masters/resigntype",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/ResignType/ResignType")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/masters/roletype",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/RoleType/RoleType")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/masters/salarycomponent",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/SalaryComponent/SalaryComponent")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/masters/salarycomponentdetail",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/SalaryComponentDetails/SalaryComponentDetails")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/masters/section",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/Section/Section")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/masters/subcontractor",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/SubContract/SubContract")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/masters/holiday",
    guard: true,
    component: lazy(() => import("./views/Admin/MasterTabs/Holiday/Holiday")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "dashboard",
    guard: true,
    component: lazy(() => import("./views/Admin/Dashboard")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/attendance",
    guard: true,
    component: lazy(() => import("./views/Admin/Attendance")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/location",
    guard: true,
    component: lazy(() => import("./views/Admin/Tabs/Location/Location")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/project",
    guard: true,
    component: lazy(() => import("./views/Admin/Tabs/Projects/Project")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "leave",
    guard: true,
    component: lazy(() => import("./views/Admin/Leave")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/documents",
    guard: true,
    component: lazy(() => import("./views/Admin/Tabs/Documents/Documents")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/documents/add-documents",
    guard: true,
    component: lazy(() => import("./views/Admin/Tabs/Documents/AddDocuments")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "location",
    guard: true,
    component: lazy(() => import("./views/Admin/Location")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/expense",
    guard: true,
    component: lazy(() => import("./views/Admin/Tabs/Expense/Expense")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/assets",
    guard: true,
    component: lazy(() => import("./views/Admin/Tabs/Asset/Asset")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/employee",
    guard: true,
    component: lazy(() => import("./views/Admin/Employee/Employee")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "Addemployee",
    component: lazy(() => import("./views/Admin/Employee/AddEmployee")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/attendance",
    component: lazy(() => import("./views/Admin/Attendance")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/attendance/monthly-attendance",
    component: lazy(() => import("./views/Admin/Tabs/Attendance/MonthlyAttendance")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/attendance/view-attendance",
    component: lazy(() => import("./views/Admin/Attendance")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/attendance/presentee-rule",
    component: lazy(() => import("./views/Admin/Tabs/Attendance/PresenteeRule")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/attendance/lmed-rule",
    component: lazy(() => import("./views/Admin/Tabs/Attendance/LMEDRule")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/admin",
    component: lazy(() => import("./views/Admin/Admin")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/assignRole",
    component: lazy(() => import("./views/Admin/Tabs/UserAdmin/AssignRole")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/dashboard/viewAdmin",
    component: lazy(() => import("./views/Admin/Tabs/UserAdmin/ViewAdmin")),
    layout: DashboardLayout,
  },

  // Employee PopOver
  {
    exact: true,
    path: "/employee/employeeTransfer",
    component: lazy(() => import("./views/Admin/Employee/PopoversEmployee/EmployeeTransfer")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/employee/employeeshift",
    component: lazy(() => import("./views/Admin/Employee/PopoversEmployee/EmployeeShift")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/employee/otRule",
    component: lazy(() => import("./views/Admin/Employee/PopoversEmployee/OtRule")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/employee/otCRule",
    component: lazy(() => import("./views/Admin/Employee/PopoversEmployee/OtCRule")),
    layout: DashboardLayout,
  },
  {
    exact: true,
    path: "/employee/approvedMatrix",
    component: lazy(() => import("./views/Admin/Employee/PopoversEmployee/ApprovedMatrix")),
    layout: DashboardLayout,
  },

];


