import { useEffect, useRef } from 'react';

const useIdleTimeout = (onTimeout, timeout = 1800000) => {
  const timeoutId = useRef(null);

  const resetTimeout = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }
    timeoutId.current = setTimeout(onTimeout, timeout);
  };

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll', 'click'];

    const reset = () => resetTimeout();

    events.forEach(event => window.addEventListener(event, reset));

    resetTimeout();

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      events.forEach(event => window.removeEventListener(event, reset));
    };
  }, []);

  return null;
};

export default useIdleTimeout;