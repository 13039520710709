
import { Spinner } from "flowbite-react";

function TableLoader() {
    return (
        <div className="flex flex-wrap items-center gap-2 justify-center">
            <Spinner color="info" aria-label="Info spinner example" size="lg"/>
        </div>
    )
};

export default TableLoader;